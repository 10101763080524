// THEME LAYOUT
"use client";
import React, { useEffect } from "react";

import { useSelector } from "react-redux";
import type { RootState } from "../GlobalRedux/Store";

import { GoogleOAuthProvider } from "@react-oauth/google";

const clientId = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID;

function Layout({ children }: { children: React.ReactNode }) {
  const theme = useSelector((state: RootState) => state.theme.themeValue);

  useEffect(() => {
    document.body.style.backgroundColor =
      theme === "dark" ? "#121212  " : "#FFF";
    document.body.style.color = theme === "dark" ? "#FFF" : "#000";
  }, [theme]);

  useEffect(() => {
    // Dynamically load Prism.js
    const script = document.createElement("script");
    script.src = "/prism/prism.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup script on unmount
      document.body.removeChild(script);
    };
  }, []);

  return (
    <GoogleOAuthProvider clientId={clientId as string}>
      {children}
    </GoogleOAuthProvider>
  );
}

export default Layout;
